import { render, staticRenderFns } from "./showUserDetails.vue?vue&type=template&id=babe70f2&scoped=true&"
import script from "./showUserDetails.vue?vue&type=script&lang=js&"
export * from "./showUserDetails.vue?vue&type=script&lang=js&"
import style0 from "./showUserDetails.vue?vue&type=style&index=0&id=babe70f2&scoped=true&lang=scss&"
import style1 from "./showUserDetails.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "babe70f2",
  null
  
)

export default component.exports