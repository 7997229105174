<template>
  <div v-loading="$store.getters['user/userLoad']" class="px-md-3 col">
    <card class="card-user position-relative">
      <!-- //? start user photo  -->
      <div class="user_bg_img" slot="image">
        <!-- <div class=""> -->
        <img
          class="d-none"
          src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
          alt="..."
        />
        <div class="new_user_background"></div>
        <!-- </div> -->
      </div>
      <!-- //? end user photo  -->

      <div>
        <div class="author">
          <img class="avatar border-dark" :src="image" alt="..." />
        </div>
      </div>

      <!-- //? Score Cards  -->
      <!--//!  Medium & Small Screens  -->
      <div class="my-4 d-lg-none">
        <div class="d-flex justify-content-center flex-column">
          <!-- Sequence  -->
          <div class="d-flex align-items-center">
            <p class="label sequence">
              {{ userScore.sequence != null ? userScore.sequence : 0 }}
            </p>
            <span class="main_words_of_scores">{{
              localization("Sequence")
            }}</span>
          </div>
          <!-- Precision  -->
          <div class="d-flex align-items-center">
            <p class="label precision">
              {{ userScore.precision != null ? userScore.precision : 0 }}
            </p>
            <span class="main_words_of_scores">{{
              localization("precision")
            }}</span>
          </div>
          <!-- Technical Reasoning  -->
          <div class="d-flex align-items-center">
            <p class="label technical_reasoning flex-shrink-0">
              {{
                userScore.technical_reasoning != null
                  ? userScore.technical_reasoning
                  : 0
              }}
            </p>
            <span class="main_words_of_scores">{{
              localization("Technical Reasoning")
            }}</span>
          </div>
          <!-- Confluence  -->
          <div class="d-flex align-items-center">
            <p class="label confluence">
              {{ userScore.confluence != null ? userScore.confluence : 0 }}
            </p>
            <span class="main_words_of_scores">{{
              localization("Confluence")
            }}</span>
          </div>
        </div>
      </div>
      <!--//!  Medium & Small Screens  -->

      <!-- //? Large Screens  -->
      <div class="my-4 d-none d-lg-block">
        <UserPattern :userScore="userScore" :visible="true"></UserPattern>
      </div>
      <!-- //? Large Screens  -->
      <div class="user_details">
        <card>
          <div class="every_item_data" :class="{ with_rtl: isRtl() }">
            <span class="d-block">{{ localization("First Name") }}</span>
            <span class="d-block">{{ user.first_name }}</span>
          </div>
          <div
            class="every_item_data with_white"
            :class="{ with_rtl: isRtl() }"
          >
            <span class="d-block">{{ localization("Last Name") }}</span>
            <span class="d-block">{{ user.last_name }}</span>
          </div>
          <div class="every_item_data" :class="{ with_rtl: isRtl() }">
            <span class="d-block">{{ localization("Email") }}</span>
            <span class="d-block">{{ user.email }}</span>
          </div>
          <div
            class="every_item_data with_white"
            :class="{ with_rtl: isRtl() }"
          >
            <span class="d-block">{{ localization("City") }}</span>
            <span class="d-block">{{ user.city }}</span>
          </div>
          <div class="every_item_data" :class="{ with_rtl: isRtl() }">
            <span class="d-block">{{ localization("State") }}</span>
            <span class="d-block">{{ user.state }}</span>
          </div>
          <div
            class="every_item_data with_white"
            :class="{ with_rtl: isRtl() }"
          >
            <span class="d-block">{{ localization("Country") }}</span>
            <span class="d-block">{{ user.country }}</span>
          </div>
        </card>
      </div>

      <!-- $store.getters['user/user']" -->
    </card>
  </div>
</template>

<script>
import Edit from "./edit.vue";
import Info from "../../components/Cards/Info";
import Index from "../tasks/index.vue";
import { Progress } from "element-ui";
import Swal from "sweetalert2";
import { Card } from "src/components/index";
export default {
  data() {
    return {
      score: {},
      score_dialog: false,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      edit_user_dialog: false,
      edit_tasks_dialog: false
    };
  },

  components: {
    [Progress.name]: Progress,
    Edit,
    Index,
    Info,
    Card
  },

  computed: {
    user: function() {
      return this.$store.state.user.user;
      // $store.getters['user/user']"
    },
    image: function() {
      if (this.user.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.user.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userScorePercentage() {
      // this is for score display as a percentage, if it is not display as percentage on mobile, delete this
      let score = { ...this.userScore };
      for (let n in score) {
        score[n] = Math.floor((score[n] * 100) / 35);
      }
      return score;
    }
  },
  mounted() {
    this.$store.dispatch("user/user", this.$route.params.id);
  },
  methods: {
    Refresh() {
      // this.$store.dispatch("user/user", this.user_id);
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },

    save() {
      this.$store
        .dispatch("user/save", { id: `/${this.user.id}`, query: this.score })
        .then(_ => {
          this.Refresh({});
          this.$store.dispatch("user/user", this.$route.params.id);
          this.score_dialog = false;
          Swal.fire({
            text: "Your score has been updated.",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
            type: "success"
          });
        });
    },
    open_score_dialog() {
      this.score = { ...this.userScore };
      this.score_dialog = true;
    }
  }
};
</script>

<style scoped lang="scss">
/* mobile score progress design */
.user_details {
  .every_item_data {
    display: flex;
    gap: 30px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    &.with_white {
      background-color: #fff;
    }
    &.with_rtl {
      flex-direction: row-reverse;
    }
  }
}
::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;

  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;

  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;
  background-color: #c36;
}
//? start new style
.user_bg_img {
  // background: #3c3;
  height: 150px;
  overflow: unset;

  .new_user_background {
    background: url('../../assets/new-theme/profile/Image wrap.png') no-repeat center ;
    background-size: cover;
    width: auto;
    height: 100%;
    margin: auto;
  }
}
.label {
  /* border-radius: 50%;
  margin: 0.5rem;
  width: 5rem;
  height: 5rem;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.8;
  color: white; */

  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}

.letter {
  font-size: 40px;
}

.border {
  border: 2px solid white !important;
}

.number {
  line-height: 1;
}

.card.score .card-body {
  padding: 0.7rem 0 !important;
}

.card label {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: unset;
}

/* close dialog */
/* ::v-deep
  .el-dialog__wrapper
  .el-dialog
  .el-dialog__headerbtn
  .el-dialog__close {
  display: block;
} */
::v-deep .el-dialog__close {
  display: block !important;
}
.main_words_of_scores {
  font-size: 1.3rem;
  text-transform: capitalize;
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1.2rem;
  }
}

// ! custom dialog footer buttons
.user_profile_dialog_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}
</style>

<style lang="scss">
.for_user_edit_scores_dialog {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.for_user_edit_details_dialog {
  .el-dialog__body {
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 30px 0 !important;
    }
  }
}
</style>
